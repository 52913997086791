import React from "react";
import { jsx, useThemeUI } from "theme-ui";
import { Text, MotionFlex } from "./core";
import { darken } from "@theme-ui/color";
import { navigate } from "../helpers/navigate";

/** @jsx jsx */

const OldschoolButton = ({
  children,
  color,
  colorShadow,
  colorPressed,
  width = 140,
  height = 48,
  border = 2,
  to = null,
  onHoverStart,
  onHoverEnd,
  ...props
}) => {
  const { theme } = useThemeUI();
  const handleOnClick = () => {
    if (to) {
      navigate(to);
    }
  };
  return (
    <MotionFlex
      as="button"
      sx={{
        color: "white",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "12px",
        border: "none",
        outline: "none",
        "&:visited": {},
        "&:active": {},
        "&:hover": {
          outline: "none",
        },
        width,
        height,
      }}
      initial={{
        backgroundColor: color,
        boxShadow: `0 0px 0 0px ${darken(color, 0.1)(theme)}`,
        y: "0px",
        transition: {
          duration: 0.1,
        },
      }}
      whileHover={{
        backgroundColor: color,
        boxShadow: `0 5px 0 0px ${colorShadow}`,
        y: "-5px",
        transition: {
          duration: 0.2,
        },
      }}
      whileTap={{
        backgroundColor: colorPressed,
        boxShadow: `0 0px 0 1px ${darken(color, 0.1)(theme)}`,
        y: "0px",
        transition: {
          duration: 0.1,
        },
      }}
      transition={{
        ease: [0.68, -0.55, 0.265, 1.65],
      }}
      onClick={handleOnClick}
      {...props}
    >
      <Text variant="h7" uppercase>
        {children}
      </Text>
    </MotionFlex>
  );
};

export default OldschoolButton;
