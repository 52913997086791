import { maximumBlueGreen, indigoDie, charcoal } from "./baseColors";
import range from "lodash/range";

export default {
  colors: {
    text: charcoal[500],
    background: "#fff",
    background2: "#F3FBFB",
    background3: "#F8F9FA",
    white: "#fff",
    black: "#000",
    grey: "#F6F6F6",
    grey2: "#E5E5E5",
    primary: maximumBlueGreen[500],
    secondary: indigoDie[500],
  },
  fonts: {
    body: "Montserrat, system-ui, sans-serif",
    heading: "Montserrat, system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  lineHeights: {
    body: 1.5,
  },
  fontSizes: [10, 12, 16, 22, 24, 34, 54],
  sizes: {
    containerSm: "calc(768px + 32px)",
    container: "calc(960px + 32px)",
    containerLg: "calc(1116px + 32px)",
  },
  space: range(0, 336, 8),
  breakpoints: ["794px", "1024px", "1365px"],
  layout: {
    containerSm: {
      px: 2,
      maxWidth: "containerSm",
    },
    container: {
      px: 2,
    },
    containerLg: {
      px: 2,
      maxWidth: "containerLg",
    },
  },
  text: {
    default: {
      fontSize: 2,
      fontWeight: "regular",
      fontFamily: "body",
      lineHeight: "32px",
    },
    p1: {
      fontSize: 2,
      fontWeight: "regular",
      fontFamily: "body",
      lineHeight: "32px",
    },
    p2: {
      fontSize: 1,
      fontWeight: "regular",
      fontFamily: "body",
      lineHeight: "24px",
    },
    h1: {
      fontSize: [5, 6, 6],
      fontWeight: "bold",
      fontFamily: "heading",
      lineHeight: ["48px", "72px", "72px"],
    },
    h2: {
      fontSize: [4, 5, 5],
      fontWeight: "bold",
      fontFamily: "heading",
      lineHeight: ["32px", "48px", "48px"],
    },
    h3: {
      fontSize: [3, 4, 4],
      fontWeight: "bold",
      fontFamily: "heading",
      lineHeight: "32px",
    },
    h4: {
      fontSize: 3,
      fontWeight: "medium",
      fontFamily: "heading",
      lineHeight: "36px",
    },
    h5: {
      fontSize: 2,
      fontWeight: "semiBold",
      fontFamily: "heading",
      letterSpacing: 6,
      lineHeight: "24px",
    },
    h6: {
      fontSize: 2,
      fontWeight: "extraBold",
      fontFamily: "heading",
      letterSpacing: 2,
      //lineHeight: "24px",
    },
    h7: {
      fontSize: 1,
      fontWeight: "bold",
      fontFamily: "heading",
      letterSpacing: 1,
    },
  },
  buttons: {
    primary: {
      variant: "text.h7",
      textTransform: "uppercase",
      outline: "none",
      userSelect: "none",
      borderRadius: "12px",
      "&:visited": {},
      "&:active": {
        outline: "none",
      },
      "&:hover": {
        cursor: "pointer",
        outline: "none",
      },
    },
    badge: {
      variant: "text.h7",
      lineHeight: "16px",
      color: "white",
      borderRadius: "68px",
      px: "12px",
      py: "8px",
      textTransform: "uppercase",
      userSelect: "none",
      "&:visited": {},
      "&:active": {
        outline: "none",
      },
      "&:hover": {
        cursor: "pointer",
        outline: "none",
      },
    }
  },
  badges: {
    default: {
      variant: "text.h7",
      lineHeight: "16px",
      color: "white",
      borderRadius: "68px",
      backgroundColor: "#FF668B",
      px: "12px",
      py: "8px",
      textTransform: "uppercase",
    },
  },
  cards: {
    default: {
      height: "100%",
      userSelect: "none",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      position: "relative",
      backgroundColor: "white",
    },
    presse: {
      display: "flex",
      height: "100%",
      userSelect: "none",
      overflow: "hidden",
      flexDirection: "column",
      borderRadius: "16px",
      marginBottom: 3,
    },
  },
  links: {
    a: {
      display: "inline-block",
      textDecoration: "none",
      color: "text",
      fontFamily: "body",
      "&:hover": {
        cursor: "pointer",
      },
    },
    nav: {
      variant: "text.h3",
      userSelect: "none",
      display: "inline-block",
      textDecoration: "none",
      color: "text",
      fontFamily: "body",
      "&:hover": {
        cursor: "pointer",
      },
    },
    footer: {
      variant: "links.a",
      fontSize: 1,
    },
    content: {
      variant: "links.a",
      color: "#33bbbd",
      "&:hover": {
        color: "#0c4b66",
      },
    },
  },
  images: {
    default: {
      userDrag: "none",
      userSelect: "none",
    },
  },
  forms: {
    default: {
      backgroundColor: "white",
      borderRadius: "12px",
      border: "1px solid",
      borderColor: "#EAF1F3",
      fontFamily: "body",
      padding: "15px 17px",
      outline: "none",
      color: "text",
      "&::placeholder": {
        color: "#9A9A9A",
      },
      "&:focus": {
        borderColor: "primary",
      },
    },
    input: {
      variant: "forms.default",
    },
    textarea: {
      variant: "forms.default",
    },
    newsletter: {
      borderRadius: "12px",
      border: "1px solid",
      borderColor: "white",
      color: "white",
      padding: "15px 17px",
      gap: "15px",
      "&::placeholder": {
        color: "#5D8799",
      },
    },
    select: {
      backgroundColor: "white",
      borderRadius: "12px",
      border: "1px solid",
      fontFamily: "Montserrat, system-ui, sans-serif",
      outline: "none",
      display: "flex",
    },
  },
  styles: {
    svg: {
      userSelect: "none",
    },
  },
};
