import Select from "react-select";
import {alpha} from "@theme-ui/color";
import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import {useThemeUI} from "theme-ui";

const CoreSelect = ({options = [], placeholder = "Select", value, defaultValue, fontSize = "16px", onChange}) => {
  const { theme } = useThemeUI();
  const primaryColor = useThemeColor("primary");
  const textColor = useThemeColor("text");

  const customStyles = {
    control: (provided, state) => ({
      // ...provided,
      ...theme.forms.select,
      borderColor: state.isFocused ? primaryColor : "#EAF1F3",
      cursor: "pointer",
      fontSize,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: '1px solid #ccc', // Ligne fine en bas
      borderRadius: 0, // Pas de bordures arrondies
      boxShadow: 'none', // Pas d'ombre
      minHeight: '30px',
      '&:hover': {
        borderBottom: '1px solid #000', // Bordure plus foncée au survol
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
      margin: 0,
      fontSize
    }),
    option: (provided, { isFocused, isSelected }) => ({
      fontFamily: "Montserrat",
      padding: "12px 15px",
      color: textColor,
      cursor: "pointer",
      backgroundColor: isFocused && alpha("primary", 0.25)(theme),
      ":active": {
        backgroundColor: alpha("primary", 0.5)(theme),
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "4px",
    }),
    placeholder: () => ({
      color: "#9A9A9A",
      fontSize,
    }),
    indicatorSeparator: () => ({
      display: 'none', // Supprime le séparateur entre le texte et la flèche
    }),
    menu: provided => ({ ...provided, fontSize, zIndex: 10, marginTop: '0px', }),
    input: (provided) => ({
      ...provided,
      margin: '0', // Supprime les marges autour de l'input
      padding: '2px 0', // Contrôle le padding interne (vertical et horizontal)
    })
  };
  return (
    <Select
      placeholder={placeholder}
      options={options}
      value={value}
      defaultValue={defaultValue}
      isSearchable={false}
      onChange={onChange}
      styles={customStyles}
    />
  )
}

export { CoreSelect as Select };
